import { render, staticRenderFns } from "./EditProfileView.vue?vue&type=template&id=0ed3decf&scoped=true"
import script from "./EditProfileView.vue?vue&type=script&lang=js"
export * from "./EditProfileView.vue?vue&type=script&lang=js"
import style0 from "./EditProfileView.vue?vue&type=style&index=0&id=0ed3decf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed3decf",
  null
  
)

export default component.exports