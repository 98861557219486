<template>
  <div class="main-view">
    <div class="settings-sidebar">
      <SettingsNavigation />
    </div>
    <div class="settings-main">
      <h1 class="headline">{{ $t("main.PROFILE") }}</h1>
      <p v-html="$t('main.PROFILE_INTRO')" />

      <h2 class="title mt-8">{{ $t("main.PROFILE_CUSTOMIZE") }}</h2>

      <v-snackbar v-model="showSavedMessage" :timeout="2000" color="success">{{ $t('main.SAVED') }}</v-snackbar>
      <v-snackbar v-model="showUploadMessage" :timeout="2000" color="success">{{ $t('main.UPLOADED') }}</v-snackbar>


      <div v-if="!user || (!user.isDone && user.isLoading)">
        <v-progress-circular indeterminate color="primary" />
      </div>

      <v-form
        v-if="user && user.isDone"
        ref="userForm"
        v-model="formUser.valid"
        :lazy-validation="false"
      >
        <div v-if="$hasPermission('a3m_core.UserProfile')">
          <v-row>
            <v-col><v-text-field v-model="formUser.firstName" :label="$t('main.FIRST_NAME')" :rules="rules.required"></v-text-field></v-col>
            <v-col><v-text-field v-model="formUser.lastName" :label="$t('main.LAST_NAME')" :rules="rules.required"></v-text-field></v-col>
            <v-col><v-text-field v-model="formUser.email" :label="$t('main.EMAIL_ADDRESS')" append-icon="mail" :rules="rules.emailRules"></v-text-field></v-col>
          </v-row>

          <div class="text-right">
            <v-btn color="primary" @click="saveUser" :disabled="!formUser.valid" :loading="user.isLoading">{{ $t("main.SAVE_ACTION") }}</v-btn>
          </div>
        </div>
        <div v-if="!$hasPermission('a3m_core.UserProfile')">
          {{ $t("main.MISSING_PERMISSION_TO_EDIT") }}
        </div>
      </v-form>

      <h2 class="title mt-4">{{ $t("main.ORGANISATION_CUSTOMIZE") }}</h2>

      <div v-if="$hasPermission('a3m_core.OrganisationProfile')">
        <v-row>
          <v-col :cols="3">
            <v-card class="mt-4">
              <v-container class="text-center py-8">
                <div v-if="!logoUrl">{{ $t("main.NO_LOGO_UPLOADED_YET") }}</div>
                <div v-if="logoUrl">
                  <img class="logo-image" :src="logoUrl" />
                </div>

                <OrganisationLogoUpload v-if="organisation && organisation.data.id" :organisation="organisation.id" @upload="logoUploaded"/>
              </v-container>
            </v-card>
          </v-col>

          <v-col :cols="1"></v-col>

          <v-col>
            <div v-if="!organisation || (!organisation.isDone && organisation.isLoading)">
              <v-progress-circular indeterminate color="primary" />
            </div>

            <v-form
              v-if="organisation && organisation.isDone"
              ref="organisationForm"
              v-model="formOrganisation.valid"
              :lazy-validation="false"
            >
              <v-row>
                <v-col>
                  <h3 class="h3">{{ $t("main.CONTACTDETAILS") }}</h3>
                </v-col>
              </v-row>

              <v-row>
                <v-col><v-text-field v-model="formOrganisation.name" :label="$t('main.NAME')" required :rules="rules.required"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.publicName" :label="$t('main.PUBLIC_NAME')" required></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.phone" :label="$t('main.TELEPHONE')" append-icon="phone"></v-text-field></v-col>
                <v-col><v-text-field v-model="formOrganisation.fax" :label="$t('main.FAX')" append-icon="print"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.email" :label="$t('main.EMAIL_ADDRESS')" append-icon="mail" :rules="rules.emailRules"></v-text-field></v-col>
                <v-col><v-text-field v-model="formOrganisation.website" :label="$t('main.WEBSITE')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.instagram" :label="$t('main.INSTAGRAM')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
                <v-col><v-text-field v-model="formOrganisation.facebook" :label="$t('main.FACEBOOK')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.linkedIn" :label="$t('main.LINKEDIN')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
                <v-col><v-text-field v-model="formOrganisation.whatsapp" :label="$t('main.WHATSAPP')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.youtube" :label="$t('main.YOUTUBE')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
                <v-col><v-text-field v-model="formOrganisation.xing" :label="$t('main.XING')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.twitter" :label="$t('main.TWITTER')" append-icon="link" :rules="rules.urlRules"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.street" :label="$t('main.STREET_ADDRESS')"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="formOrganisation.zipCode" :label="$t('main.ZIP_CODE')"></v-text-field></v-col>
                <v-col><v-text-field v-model="formOrganisation.city" :label="$t('main.CITY')"></v-text-field></v-col>
                <v-col><v-select v-model="formOrganisation.countryIso2" :items="countries" :label="$t('main.COUNTRY')"></v-select></v-col>
              </v-row>


              <v-row>                
                <v-col style="color: grey;">
                  Organisations-ID: {{ this.organisation.data.idCode }}
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn color="primary" @click="saveOrganisation">{{ $t("main.SAVE_ACTION") }}</v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </div>
      <div v-if="!$hasPermission('a3m_core.OrganisationProfile')">
        {{ $t("main.MISSING_PERMISSION_TO_EDIT") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { backendMediaUrl } from '@/settings'
import SettingsNavigation from "@/components/settings/SettingsNavigation";
import OrganisationLogoUpload from '@/components/settings/OrganisationLogoUpload'

export default {
  name: 'EditProfileView',
  components: {
    OrganisationLogoUpload,
    SettingsNavigation
  },
  mounted() {
    this.loadOrganisationUser()
    this.loadOrganisation()
    this.fillUserForm()
  },
  data() {
    return {
      showSavedMessage: false,
      showUploadMessage: false,

      formUser: {
        valid: false,
        firstName: "",
        lastName: "",
        email: "",
      },
      formOrganisation: {
        valid: false,
        name: "",
        phone: "",
        fax: "",
        email: "",
        website: "",
        instagram: "",
        facebook: "",
        linkedIn: "",
        whatsapp: "",
        youtube: "",
        xing: "",
        twitter: "",
        street: "",
        zipCode: "",
        city: "",
        countryIso2: ""
      },

      countries: [
        {"value": "DE", "text": this.$t('main.GERMANY')},
        {"value": "DK", "text": this.$t('main.DENMARK')},
        {"value": "FR", "text": this.$t('main.FRANCE')},
        {"value": "NL", "text": this.$t('main.NETHERLANDS')},
        {"value": "AT", "text": this.$t('main.AUSTRIA')},
        {"value": "PL", "text": this.$t('main.POLAND')},
        {"value": "CH", "text": this.$t('main.SWISS')},
        {"value": "CZ", "text": this.$t('main.CZECH')},
      ],

      rules: {
        required: [
          v => !!v || this.$t('main.REQUIRED_FIELD'),
        ],
        emailRules: [
          v => !v || /.+@.+\..+/.test(v) || this.$t('main.EMAIL_ADDRESS_IS_INVALID'),
        ],
        urlRules: [
          v => !v || this.isURL(v) || this.$t('main.URL_IS_INVALID'),
        ]
      }
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.authStore.user,
      authOrganisation: state => state.authStore.organisation,
    }),
    ...mapGetters({
      organisationUserGetById: 'organisationUserStore/getById',
      organisationGetById: 'organisationStore/getById'
    }),
    user() {
      if (!this.authUser || !this.authUser.id) {
        return null
      }
      return this.organisationUserGetById(this.authUser.id)
    },
    organisation() {
      if (!this.authOrganisation || !this.authOrganisation.id) {
        return null
      }
      return this.organisationGetById(this.authOrganisation.id)
    },
    logoUrl() {
      if (this.organisation && this.organisation.isDone && this.organisation.data.logo != '') {
        return backendMediaUrl + '/' + this.organisation.data.logo
      }

      return null
    }
  },
  methods: {
    ...mapActions({
      organisationUserFetch: 'organisationUserStore/fetch',
      organisationUserUpdate: 'organisationUserStore/update',

      organisationFetch: 'organisationStore/fetch',
      organisationUpdate: 'organisationStore/update',
    }),

    /**
     * The authUser might not contain all required information, so we load
     * the most current full version of the user from the API
     */
    loadOrganisationUser() {
      if (this.authUser && this.authUser.id) {
        this.organisationUserFetch({
          variables: {
            id: this.authUser.id
          },
          force: true
        }).then(() => {
          this.fillUserForm()
        })
      }
    },
    loadOrganisation() {
      if (this.authOrganisation && this.authOrganisation.id) {
        this.organisationFetch({
          variables: {
            id: this.authOrganisation.id
          },
          force: true
        }).then(() => {
          this.fillOrganisationForm()
        })
      }
    },
    fillUserForm() {
      if (this.user && this.user.isDone) {
        this.formUser.firstName = this.user.data.firstName
        this.formUser.lastName = this.user.data.lastName
        this.formUser.email = this.user.data.email
      }
    },

    fillOrganisationForm() {
      if (this.organisation && this.organisation.isDone) {
        this.formOrganisation.name = this.organisation.data.name
        this.formOrganisation.publicName = this.organisation.data.publicName
        this.formOrganisation.phone = this.organisation.data.phone
        this.formOrganisation.fax = this.organisation.data.fax
        this.formOrganisation.email = this.organisation.data.email
        this.formOrganisation.website = this.organisation.data.website
        this.formOrganisation.instagram = this.organisation.data.instagram
        this.formOrganisation.facebook = this.organisation.data.facebook
        this.formOrganisation.linkedIn = this.organisation.data.linkedIn
        this.formOrganisation.whatsapp = this.organisation.data.whatsapp
        this.formOrganisation.youtube = this.organisation.data.youtube
        this.formOrganisation.xing = this.organisation.data.xing
        this.formOrganisation.twitter = this.organisation.data.twitter
        this.formOrganisation.street = this.organisation.data.street
        this.formOrganisation.zipCode = this.organisation.data.zipCode
        this.formOrganisation.city = this.organisation.data.city
        this.formOrganisation.countryIso2 = this.organisation.data.countryIso2
      }
    },

    saveUser() {
      this.$refs.userForm.validate()

      if (this.formUser.valid) {
        let updateData = {
          id: this.authUser.id,
          firstName: this.formUser.firstName,
          lastName: this.formUser.lastName,
          email: this.formUser.email
        }

        this.organisationUserUpdate({
          variables: {
            input: updateData
          }
        }).then(() => {
          this.showSavedMessage = true
        })
      }
    },
    saveOrganisation() {
      this.$refs.organisationForm.validate()

      if (this.formOrganisation.valid) {
        let updateData = {
          id: this.authOrganisation.id,
          name:this.formOrganisation.name,
          publicName: this.formOrganisation.publicName,
          phone: this.formOrganisation.phone,
          fax: this.formOrganisation.fax,
          email: this.formOrganisation.email,
          website: this.formOrganisation.website,
          instagram: this.formOrganisation.instagram,
          facebook: this.formOrganisation.facebook,
          linkedIn: this.formOrganisation.linkedIn,
          whatsapp: this.formOrganisation.whatsapp,
          youtube: this.formOrganisation.youtube,
          xing: this.formOrganisation.xing,
          twitter: this.formOrganisation.twitter,
          street: this.formOrganisation.street,
          zipCode: this.formOrganisation.zipCode,
          city: this.formOrganisation.city,
          countryIso2: this.formOrganisation.countryIso2
        }

        this.organisationUpdate({
          variables: {
            input: updateData
          }
        }).then(() => {
          this.showSavedMessage = true
        })
      }
    },
    logoUploaded() {
      this.loadOrganisation()
      this.showUploadMessage = true
    },
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
  },
  watch: {
    authUser() {
      // reload user if the WhoAmI call finished after this form was done
      this.loadOrganisationUser()
    },
    authOrganisation() {
      // reload user if the WhoAmI call finished after this form was done
      this.loadOrganisation()
    },
  }
}
</script>

<style scoped>
  .main-view {
    position: relative;
    padding: 2rem;
    max-width: 1250px;
    margin: auto;
  }

  .logo-image {
    max-width: 100%;
    max-height: 100%;
  }

  .settings-sidebar {
    top: 2rem;
    width: 225px;
    position: absolute;
    left: 0;
  }

  .settings-main {
    position: absolute;
    left: 250px;
    right: 0;
  }
</style>
