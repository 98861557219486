<template>
  <div>
    <h2 class="title" style="font-weight: normal">{{ $t("main.SETTINGS") }}</h2>

    <v-list>
      <v-list-item-group color="indigo">
        <v-list-item
          :to="{name: 'profile-edit'}"
          >
          <v-list-item-content>
            <v-list-item-title>{{ $t("main.PROFILE") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$hasPermission('a3m_core.DestinationManager')"
          :to="{name: 'organisation-countries-selection'}"
          >
          <v-list-item-content>
            <v-list-item-title>{{ $t("main.COUNTRY_SELECTION") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
             v-if="$hasPermission('a3m_core.DestinationManager')"
            :to="{name: 'mytrip-filter-settings'}"
        >
          <v-list-item-title>{{ $t("main.MYJOURNEY_PAGE") }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "SettingsNavigation",
}
</script>