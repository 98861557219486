<template>
  <div>
    <v-file-input v-model="file" accept="image/*" show-size  label="Datei ändern"></v-file-input>
    <v-btn v-if="file" color="primary" @click="submit">{{ $t("main.LOGO_UPLOAD_ACTION") }}</v-btn>
  </div>
</template>

<script>
import axiosClient from '@/axiosClient';
import { backendAuthUrl } from '@/settings';

export default {
  name: "OrganisationLogoUpload",
  props: [
    'organisation'
  ],
  data() {
    return {
      file: null
    };
  },
  methods: {
    submit() {
      let formData = new FormData();
      formData.append("organisation_id", this.organisation);
      formData.append("logo", this.file);

      axiosClient
        .post(backendAuthUrl + "/organisation/upload/logo", formData)
        .then(() => {
          this.file = null
          this.$emit('upload')
        })
        .catch(() => {
          this.file = null
        });
    }
  }
};
</script>